<template>
    <div>    
        <b-overlay :show="isSavingSubCondo">
            <b-card>  
                <div class="head-create">
                <h1>Crear sub condominio</h1>
                    <b-button
                        :to="{ name: 'sub-condominiums'}"               
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"                                  
                    >
                        <feather-icon icon="ArrowLeftIcon" style="color: white" />  
                    </b-button>
                </div>                                           
                <ValidationObserver ref="createGroupSubCondos" v-slot="{ invalid }">
                    <b-form @submit.prevent="saveSubCondon">
                    <b-row>
                        <b-col md="3">
                    
                        <ValidationProvider name="condominios" rules="required">
                            <b-form-group label="Condominios" slot-scope="{ valid, errors }">
                            <b-form-select
                                :state="errors[0] ? false : valid ? true : null"
                                v-model="condosId"
                            >
                                <option value="">Seleccione Condominio</option>
                                <option
                                v-for="condosList in condos"
                                :key="condosList.id"
                                :value="condosList.id"
                                >
                                {{ condosList.name }}
                                </option>
                            </b-form-select>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>

                        
                        <b-col md="3">
                        <ValidationProvider rules="required" name="nombre">
                            <b-form-group label="Nombre" slot-scope="{ valid, errors }">
                            <b-form-input
                                class="form-control"
                                type="text"
                                v-model="name"
                                :state="errors[0] ? false : valid ? true : null"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                                <b-col md="3">
                        <ValidationProvider rules="required" name="porcentaje">
                            <b-form-group label="Porcentaje" slot-scope="{ valid, errors }">
                            <b-form-input
                                class="form-control"
                                type="text"
                                v-model="percentage"
                                :state="errors[0] ? false : valid ? true : null"
                            >
                            </b-form-input>
                            <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </ValidationProvider>
                        </b-col>
                    
                    </b-row>
                    <b-row>
                        <b-col>
                        <div class="float-right">
                            <b-button
                            type="submit"
                            class="btn-block"
                            variant="primary"
                            :disabled="invalid || isSavingSubCondo"
                            >
                            <b-spinner small v-if="isSavingSubCondo" /> Guardar
                            </b-button>
                        </div>
                        </b-col>
                    </b-row>
                    </b-form>
                </ValidationObserver>
            </b-card>
        </b-overlay>
    </div>
</template>



<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { showAlertMessage } from '@/helpers/helpers'
import Ripple from 'vue-ripple-directive'
   
export default {
    directives: {Ripple, },
    data() {
        return {
            name: '',
            condosId:'',
            status: true,
            percentage:'',
            isSavingSubCondo: false,
        }
    },
    computed: {
        ...mapState('fivesClubCatalogs', ['condos']),
        ...mapState('auth', ['user']),
    },
    methods: {
        ...mapActions('fivesClubCatalogs', ['saveSubCondos','fetchSubCondos']),
        ...mapMutations('fivesClubCatalogs', ['setSubCondos']),
        async saveSubCondon() {
            this.isSavingSubCondo = true
            const payload = {
                idUser: this.user.idUser,
                name: this.name,
                condos:this.condosId,
                percentage:this.percentage,        
            }
            const { status, message } = await this.saveSubCondos(payload)
            if (status) {
                showAlertMessage('Ok: proceso finalizado', 'InfoIcon', message, 'success', 4000, 'bottom-right' )
                Object.assign(this.$data, this.$options.data())
                this.$refs.createGroupSubCondos.reset() //reseteo form
                //vuelvo a pedir la info del back
                const SubCondos = await this.fetchSubCondos()
                this.setSubCondos(SubCondos)        
            }
            this.isSavingSubCondo = false
        },
    },
};
</script>


<style scoped>
.head-create{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.head-create h1{
    font-size: 1.5rem;
    color: #000;
}
</style>